export const rowKey = "other_order_id";
import { 
  // formatOrderSource,
  formatGoodsType,
} from '@/utils/type'


export const columns_other_order_goods_list = [
  {
    title: "分类",
    dataIndex: "goods_type",
    align: "center",
    width: 80,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "SKU码",
    dataIndex: "goods_sku_number",
    align: "center",
    width: 100,
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
    width: "30%",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
    width: "30%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: 80,
  },
  {
    title: "商品价格",
    width: 120,
    dataIndex: "goods_price",
    align: "center",
  },
  {
    title: "支付价格",
    width: 120,
    dataIndex: "order_price",
    align: "center",
  },
  {
    title: "快递发货",
    dataIndex: "is_express",
    align: "center",
    width: 100,
    slots: { customRender: "is_express" },
  },
];

export const columns_inner_add = [
  {
    title: "分类",
    dataIndex: "goods_type",
    align: "center",
    width: 80,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "SKU码",
    dataIndex: "goods_sku_number",
    align: "center",
    width: 100,
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },

  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
    width: "30%",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
    width: "30%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    slots: { customRender: "number" },
    width: 110,
  },
  {
    title: "商品价格",
    dataIndex: "goods_price",
    align: "center",
    width: 100,
  },
  {
    title: "订单价格",
    dataIndex: "order_price",
    align: "center",
    slots: { customRender: "order_price" },
    width: 130,
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 100,
    slots: { customRender: "operation" },
  },
];

export default {
  rowKey,
  columns_other_order_goods_list
};












